.app_container {
  display: flex;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background: #F6F5FF;
}

.form_box {
  padding: 42px;
  border: 1px solid rgba(5, 5, 5, 0.06);
  border-radius: 6px;
  background-color: #FFFFFF;
}

.button_box {
  display: flex;
}
